<template>
  <div class="app-container topology-list">
    <div class="buttons-line">
      <el-button type="primary" icon="el-icon-plus" @click="addNew"
        >新建</el-button
      >
      <span>
        <el-button type="primary" plain @click="uploadModel = true"
          >数据上传</el-button
        >
        <el-button type="primary" plain @click="downPropertyTemplate"
          >模板下载</el-button
        >
      </span>
    </div>
    <div class="card-container" v-loading="listLoading">
      <el-row :gutter="30">
        <el-col
          :xs="8"
          :sm="8"
          :md="8"
          :lg="6"
          :xl="4"
          v-for="(card, index) in cardList"
          :key="index"
        >
          <div class="single-card" @click="cardClickHandler(card)">
            <div class="background-part">
              <div
                :class="[
                  'status-icon-container',
                  card.publishTopoJson && 'status-icon-publish',
                ]"
              ></div>
              <div class="operation-container" @click.stop>
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    <img
                      src="@/assets/images/topology/operation_icon.png"
                      alt=""
                    />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="composeValue(card, 'preview')"
                      >预览</el-dropdown-item
                    >
                    <el-dropdown-item :command="composeValue(card, 'rename')"
                      >重命名</el-dropdown-item
                    >
                    <el-dropdown-item :command="composeValue(card, 'copy')"
                      >复制</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="composeValue(card, 'lookLink')"
                      v-if="card.publishTopoJson"
                      >查看链接</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="composeValue(card, 'releaseRecord')"
                      v-if="card.publishTopoJson"
                      >发布记录</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="composeValue(card, 'delete')"
                      style="color: #ff0000"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="name-line" :title="card.topoName">
              {{ card.topoName || "新项目" }}
            </div>
            <div class="detail-line">
              <span class="name-span" :title="card.createBy">
                {{ card.createBy }}
              </span>
              <span>
                {{ card.updateTime | dateFormat("YYYY-MM-DD HH:mm") }}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      width="400px"
      :visible.sync="releaseDialog.show"
      :before-close="
        () => {
          releaseDialog.show = false;
        }
      "
    >
      <template slot="title">
        <title-icon />{{ releaseDialog.title }}
      </template>
      <el-form
        ref="releaseForm"
        :model="releaseForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item label="项目名称" prop="releaseName">
          <el-input
            style="width: 100%"
            maxlength="20"
            v-model="releaseForm.releaseName"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="renameHandler">保 存</el-button>
        <el-button
          @click="
            () => {
              releaseDialog.show = false;
            }
          "
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      width="600px"
      :visible.sync="linkDialog.show"
      :before-close="
        () => {
          linkDialog.show = false;
        }
      "
    >
      <template slot="title"> <title-icon />{{ linkDialog.title }} </template>
      <el-form ref="releaseForm" :model="linkDialog" label-position="top">
        <el-form-item label="链接" prop="link">
          <el-input
            style="width: 80%"
            readonly
            v-model="linkDialog.link"
          ></el-input>
          <el-button
            type="primary"
            @click="copoHandler"
            style="margin-left: 15px"
            >复制</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button
          type="primary"
          @click="
            () => {
              linkDialog.show = false;
            }
          "
          >关 闭</el-button
        >
      </div>
    </el-dialog>
    <TopoPublishDialog
      v-if="publishDialogShow"
      :publishDialogShow="publishDialogShow"
      :topoId="topoId"
      @close="publishDialogShow = false"
    />
    <!--导入文件-->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="uploadModel"
      width="40%"
    >
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div slot="footer">
        <upload
          :ulType="uploadType"
          :excelType="excelType"
          :taskType="taskType"
          :rootPath="rootPath"
          @success="uploadSuccess"
          @fail="uploadFail"
        ></upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  findTopo,
  editTopo,
  deleteTopo,
  createTopo,
} from "@/api/ruge/topo/topo.js";
import { downPropertyTemplate } from "@/api/ruge/vlink/device/device";
import TopoPublishDialog from "./detail/components/topoPublishDialog.vue";
import { copy2Clip } from "@/utils/global";
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
export default {
  name: "topologyList",
  components: {
    TopoPublishDialog,
    Upload,
  },
  data() {
    return {
      topoId: "",
      publishDialogShow: false,
      cardList: [],
      listLoading: false,
      releaseDialog: {
        show: false,
        title: "重命名",
        graphName: "",
        datas: {},
      },
      releaseForm: {
        releaseName: "新项目",
      },
      rules: {
        releaseName: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("validate.required"),
          },
        ],
      },
      linkDialog: {
        show: false,
        link: "",
        title: "查看链接",
      },
      uploadModel: false,
      uploadType: "ExcelImport",
      excelType: "ruge.property",
      taskType: "属性历史数据",
      rootPath: envInfo.bgApp.dorisPath,
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    downPropertyTemplate() {
      downPropertyTemplate();
    },
    uploadSuccess() {
      this.uploadModel = false;
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    copoHandler() {
      copy2Clip(this.linkDialog.link);
    },
    renameHandler() {
      this.$refs.releaseForm.validate((v) => {
        if (v) {
          const params = {
            topoName: this.releaseForm.releaseName,
            draftTopoJson: this.releaseDialog.datas.draftTopoJson,
            publishTopoJson: this.releaseDialog.datas.publishTopoJson,
            topoId: this.releaseDialog.datas.topoId,
            topoStatus: this.releaseDialog.datas.topoStatus,
          };
          editTopo(params).then(() => {
            this.releaseDialog.show = false;
            this.$message.success("重命名成功！");
            this.getTableList();
          });
        }
      });
    },
    copyHandler() {
      const params = {
        topoName: this.releaseForm.releaseName,
        draftTopoJson: this.releaseDialog.datas.draftTopoJson,
      };
      createTopo(params).then(() => {
        this.releaseDialog.show = false;
        this.$message.success("复制成功！");
        this.getTableList();
      });
    },
    getTableList() {
      this.listLoading = true;
      findTopo()
        .then((res) => {
          console.log(res);
          this.cardList = res.rows;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    composeValue(datas, command) {
      return {
        command,
        datas,
      };
    },
    deleteHandler(topoId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
          closeOnClickModal: false, //是否可以点击空白处关闭弹窗
        }
      ).then(() => {
        deleteTopo(topoId).then(() => {
          this.$message.success("删除成功！");
          this.getTableList();
        });
      });
    },
    handleCommand(composeDatas) {
      const { command, datas } = composeDatas;
      switch (command) {
        case "rename":
          this.releaseDialog.show = true;
          this.releaseDialog.datas = datas;
          this.releaseForm.releaseName = datas.topoName;
          break;
        case "copy":
          this.releaseForm.releaseName = `${datas.topoName}-副本`;
          this.releaseDialog.datas = datas;
          this.copyHandler();
          break;
        case "delete":
          this.deleteHandler(datas.topoId);
          break;
        case "releaseRecord":
          this.topoId = datas.topoId;
          this.publishDialogShow = true;
          break;
        case "lookLink":
          const link = `${location.origin}${location.pathname}#/iot/topology/preview?layout=hide&publish=true&topoId=${datas.topoId}`;
          this.linkDialog.link = link;
          this.linkDialog.show = true;
          break;
        case "preview":
          const url = `${location.origin}${location.pathname}#/iot/topology/preview?layout=hide&topoId=${datas.topoId}`;
          window.open(url, "_blank");
          break;
      }
    },
    cardClickHandler(card) {
      this.$router.push(
        `/iot/topology/detail?layout=hide&topoId=${card.topoId}`
      );
    },
    addNew() {
      this.$router.push(`/iot/topology/detail?layout=hide`);
    },
  },
};
</script>

<style lang="less" scoped>
.topology-list {
  .buttons-line {
    display: flex;
    justify-content: space-between;
  }
  .card-container {
    margin-top: 30px;
    .single-card {
      height: 250px;
      box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: #ffffff;
      margin-bottom: 30px;
      cursor: pointer;
      .background-part {
        height: 173px;
        width: 100%;
        background: url("../../../../assets/images/topology/list_background.png");
        background-size: 100% 100%;
        position: relative;
        .status-icon-container {
          height: 42px;
          width: 42px;
          position: absolute;
          left: 0;
          top: 0;
          background: url("../../../../assets/images/topology/list_draft_icon.png");
        }
        .status-icon-publish {
          background: url("../../../../assets/images/topology/list_release_icon.png");
        }
        .operation-container {
          position: absolute;
          top: 7px;
          right: 10px;
          cursor: pointer;
        }
      }
      .name-line {
        padding: 0 14px;
        font-size: 16px;
        font-weight: 600;
        color: #152c5b;
        line-height: 20px;
        margin-top: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .detail-line {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 14px;
        font-size: 12px;
        font-weight: 400;
        color: #8190ac;
        line-height: 12px;
        .name-span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
        }
      }
      &:hover {
        position: relative;
        top: -6px;
        .name-line {
          color: #1a4cec;
        }
      }
    }
  }
}
</style>