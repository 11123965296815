<template>
  <div class="topo-publish-dialog">
    <el-dialog
      :close-on-click-modal="false"
      width="600px"
      :visible.sync="publishDialogShow"
      :before-close="closeHandler"
    >
      <template slot="title"> <title-icon />发布记录 </template>
      <div class="record-container" v-loading="pageLoading">
        <div
          class="single-record"
          v-for="(item, index) in releaseList"
          :key="index"
        >
          <span class="name-span">
            {{ item.topoName }}
          </span>
          <span>
            {{ item.updateTime | dateFormat("YYYY-MM-DD HH:mm") }}
          </span>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="closeHandler">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findPublishTopo } from "@/api/ruge/topo/topo.js";
export default {
  name: "topoPublishDialog",
  props: {
    publishDialogShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    topoId: {
      type: String | Number,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      releaseList: [],
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    getTableList() {
      this.pageLoading = true;
      findPublishTopo(this.topoId)
        .then((res) => {
          this.releaseList = res;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    closeHandler() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.topo-publish-dialog {
  .record-container {
    min-height: 50px;
  }
  .single-record {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #152c5b;
    .name-span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .single-record + .single-record {
    margin-top: 20px;
  }
}
</style>