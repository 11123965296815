var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "topo-publish-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            width: "600px",
            visible: _vm.publishDialogShow,
            "before-close": _vm.closeHandler,
          },
          on: {
            "update:visible": function ($event) {
              _vm.publishDialogShow = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [_c("title-icon"), _vm._v("发布记录 ")],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.pageLoading,
                  expression: "pageLoading",
                },
              ],
              staticClass: "record-container",
            },
            _vm._l(_vm.releaseList, function (item, index) {
              return _c("div", { key: index, staticClass: "single-record" }, [
                _c("span", { staticClass: "name-span" }, [
                  _vm._v("\n          " + _vm._s(item.topoName) + "\n        "),
                ]),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          item.updateTime,
                          "YYYY-MM-DD HH:mm"
                        )
                      ) +
                      "\n        "
                  ),
                ]),
              ])
            }),
            0
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeHandler } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }